<!--  -->
<template>
  <div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.dialogVisible" @close="handleClose" width="60%">
      <el-row>
        <el-col :span="8" class="flex">
          <el-input v-model="params.keyWords" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
      <el-table :data.sync="tableList" border class="margin-top-sm">
        <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
        <el-table-column label="商品图片" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.smallImg" alt style="width:48px;height:48px;" />
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="价格" align="center" width="100px">
          <template slot-scope="scope">
            <div>原价：{{ scope.stock }}</div>
            <div>开团价：{{ scope.stock }}</div>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleSelect(scope.row)" :disabled="false">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageCo">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNo"
          :page-sizes="[1, 2, 5, 10]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        />
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { goodsList } from '@/api/GoodsController'

export default {
  name: 'AddDialog',
  props: {
    dialog: {
      type: Object,
      default: function(value) {
        return {
          title: '提示',
          dialogVisible: false
        }
      }
    }
  },
  data() {
    return {
      params: {
        keyWords: '',
        pageNo: 1,
        pageSize: 5
      },
      total: 0,
      tableList: []
    }
  },
  mounted() {},
  watch: {
    ['dialog.dialogVisible'](val) {
      val && this.fecthList()
    }
  },
  methods: {
    handleSearch() {},
    handleSelect() {
      this.$router.push('/groupBayEdit')
    },
    handleConfirm() {
      this.emit('confirm', {})
    },
    async fecthList() {
      let [err, res] = await goodsList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
</style>
