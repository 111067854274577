<!-- groupBayList -->
<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="7" :sm="3" :md="2" :lg="2" :xl="2" class="flex">
          <el-button type="primary" @click="handleAdd">添加团购</el-button>
        </el-col>
        <el-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <el-select v-model="params.source" clearable placeholder="团购状态" @click="handleSearch">
            <el-option label="全部" value=""></el-option>
            <el-option label="关闭" value="1"></el-option>
            <el-option label="开启" value="2"></el-option>
            <el-option label="完成" value="3"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="10" :md="14" :lg="8" :xl="6">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="5" class="flex">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="goodsId" label="团购信息" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
      <el-table-column label="商品图片" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.smallImg" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="价格" align="center" width="100px">
        <template slot-scope="scope">
          <div>原价：{{ scope.stock }}</div>
          <div>开团价：{{ scope.stock }}</div>
        </template>
      </el-table-column>
      <el-table-column label="开团量" align="center">
        <template slot-scope="scope">
          <div>开团量：{{ scope.stock }}</div>
          <div>已开团量：{{ scope.stock }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="每人限购" align="center">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center">
        <template slot-scope="scope">
          <div>开始：</div>
          <div>结束：</div>
        </template>
      </el-table-column>
      <el-table-column label="活动状态" align="center">
        <template slot-scope="scope">
          <div>是否返佣：是</div>
          <div>活动状态：团购已完成</div>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onEidt(scope.row)" :disabled="false">编辑</el-button>
          <el-button size="mini" type="warning" @click="onEidt(scope.row)" :disabled="false">删除</el-button>
          <el-button size="mini" type="success" @click="onEidt(scope.row)" :disabled="false">开启</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
    <AddDialog :dialog="addDialog" />
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { goodsList, goodsStockUpdate, goodsStatusUpdate, categoryList, categorySecondList } from '@/api/GoodsController'
import AddDialog from './components/AddDialog.vue'

export default {
  components: {
    TitleBarBaseVue,
    AddDialog
  },
  data() {
    return {
      params: {
        source: null,
        keyWords: '',
        firstLevelCategoryId: null, //
        secondLevelCategoryId: null,
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      tableList: [],
      dtaTaAs: {
        goodsId: ''
      },
      num: '', //修改库存数量

      firstList: [],
      secondList: [],
      addDialog: {
        title: '添加团购',
        dialogVisible: false
      },
      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },

  mounted() {
    this.fecthList()
    this.onCategoryList()
  },

  methods: {
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },
    changeParams(v) {
      !v && this.handleSearch()
    },

    handleSearch() {
      console.log(this.params)
      this.fecthList()
    },

    async fecthList() {
      let [err, res] = await goodsList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },

    //获取一级分类
    async onCategoryList() {
      let [err, res] = await categoryList()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)

      this.firstList = res.data || []
    },

    //一级分类改变，获取二级分类
    async onSecondList(value) {
      if (value) {
        let param = {
          categoryId: value
        }
        let [err, res] = await categorySecondList(param)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '获取二级列表失败')
        }
        console.log(res)
        this.params.secondLevelCategoryId = null
        this.secondList = res.data || []
        this.handleSearch()
      } else {
        this.params.secondLevelCategoryId = null
        this.secondList = []
        this.handleSearch()
      }
    },

    //修改库存弹出
    onEidtStock(row) {
      this.dtaTaAs = { ...row }
      this.num = row.stock
      this.editVisible = true
    },

    // 更新库存
    async onUpdateStock() {
      let num = this.num
      if (num != '') {
        num = num - this.dtaTaAs.stock
        if (num == 0) {
          return this.$message.error('修改前后库存一样')
        }
        let param = {
          goodsId: this.dtaTaAs.goodsId,
          number: Math.abs(num),
          type: num > 0 ? 1 : 0
        }
        let [err, res] = await goodsStockUpdate(param)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '操作失败')
        }
        this.fecthList()
        this.editVisible = false
      } else {
        this.$message.error('请填写库存数量')
      }
    },

    //更新上下架
    async onUpdateStatus(row) {
      let param = {
        goodsId: row.goodsId,
        status: row.status == 1 ? 0 : 1
      }
      let [err, res] = await goodsStatusUpdate(param)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.fecthList()
    },

    //新建
    handleAdd() {
      this.addDialog.dialogVisible = true
    },

    //编辑
    onEidt(row) {
      this.$router.push({
        path: '/goodsEdit',
        query: {
          goodsId: row.goodsId
        }
      })
    }
  }
}
</script>
